/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
var o, t, u;
!function (o) {
  o[o.USER = 0] = "USER", o[o.MANAGER = 1] = "MANAGER";
}(o || (o = {})), function (o) {
  o[o.None = 0] = "None", o[o.Unfocused = 1] = "Unfocused", o[o.Focused = 2] = "Focused", o[o.Unselected = 4] = "Unselected", o[o.Selected = 8] = "Selected", o[o.All = 15] = "All";
}(t || (t = {})), function (o) {
  o[o.None = 0] = "None", o[o.Custom1 = 16] = "Custom1", o[o.Custom2 = 32] = "Custom2", o[o.Custom3 = 64] = "Custom3", o[o.Custom4 = 128] = "Custom4", o[o.Custom5 = 256] = "Custom5", o[o.Custom6 = 512] = "Custom6", o[o.Custom7 = 1024] = "Custom7", o[o.Custom8 = 2048] = "Custom8", o[o.Custom9 = 4096] = "Custom9", o[o.Custom10 = 8192] = "Custom10", o[o.Custom11 = 16384] = "Custom11", o[o.Custom12 = 32768] = "Custom12", o[o.All = 65520] = "All";
}(u || (u = {}));
export { o as EditableFlag, u as ManipulatorStateCustomFlags, t as ManipulatorStateFlags };